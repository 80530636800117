var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-2 ecommerce-application"},[_c('b-row',[_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","variant":"transparent"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('p',{staticClass:"mt-1"},[_vm._v("Registrando usuario")])],1)]},proxy:true}])},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"horizontal","finish-button-text":_vm.loading
              ? 'Procesando...'
              : _vm.$route.meta.isEstablishmentCustomer !== true
              ? 'Confirmar'
              : 'Siguiente',"next-button-text":"Siguiente","back-button-text":"Regresar","shape":"square","step-size":"sm"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Nombre","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Nombre del proveedor")])]),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"v-ammount"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-input-group',{},[_c('b-form-input',{attrs:{"id":"v-ammount","state":errors.length > 0 ? false : valid ? true : null,"placeholder":"José López","size":"lg","type":"text"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Correo y número celular","before-change":_vm.validationCelForm}},[_c('validation-observer',{ref:"accountCelRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Teléfono celular")])]),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"v-loginFieldPhone"}},[_c('validation-provider',{attrs:{"name":"El celular es necesario para validar su cuenta","rules":"required|phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"v-loginFieldPhone","state":errors.length > 0 ? false : valid ? true : null,"placeholder":"5555555555","size":"lg","maxlength":"10"},model:{value:(_vm.user.phone_number),callback:function ($$v) {_vm.$set(_vm.user, "phone_number", $$v)},expression:"user.phone_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Productos que provee","before-change":_vm.validateProducts}},[_c('b-row',[_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}}),_c('b-col',[_c('add-store-products-to-supplier-search-bar')],1)],1)],1),_c('tab-content',{attrs:{"title":"Confirmar"}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("Nombre:")]),_c('p',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("Celular:")]),_c('p',{staticClass:"display-5"},[_vm._v(" "+_vm._s(_vm.user.phone_number)+" ")])])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }