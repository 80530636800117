<template>
  <b-container class="mt-2 ecommerce-application">
    <b-row>
      <b-col>
        <b-overlay :show="loading" rounded="sm" variant="transparent">
          <template #overlay>
            <div class="text-center">
              <b-spinner />
              <p class="mt-1">Registrando usuario</p>
            </div>
          </template>
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            layout="horizontal"
            :finish-button-text="
              loading
                ? 'Procesando...'
                : $route.meta.isEstablishmentCustomer !== true
                ? 'Confirmar'
                : 'Siguiente'
            "
            next-button-text="Siguiente"
            back-button-text="Regresar"
            class="wizard-vertical mb-3"
            shape="square"
            step-size="sm"
            @on-complete="formSubmitted"
          >
            <!-- Nombre -->
            <tab-content title="Nombre" :before-change="validationForm">
              <validation-observer ref="accountRules" tag="form">
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0">Nombre del proveedor</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-ammount">
                      <validation-provider
                        #default="{ errors, valid }"
                        name="Nombre"
                        rules="required"
                      >
                        <b-input-group class="">
                          <b-form-input
                            id="v-ammount"
                            v-model="user.name"
                            :state="
                              errors.length > 0 ? false : valid ? true : null
                            "
                            placeholder="José López"
                            size="lg"
                            type="text"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Método de inicio de sesión -->
            <tab-content
              title="Correo y número celular"
              :before-change="validationCelForm"
            >
              <validation-observer ref="accountCelRules" tag="form">
                <b-row>
                  <b-col cols="12" class="my-2">
                    <h5 class="mb-0">Teléfono celular</h5>
                  </b-col>
                  <b-col>
                    <b-form-group label-for="v-loginFieldPhone">
                      <validation-provider
                        #default="{ errors, valid }"
                        name="El celular es necesario para validar su cuenta"
                        rules="required|phone_number"
                      >
                        <b-input-group>
                          <b-form-input
                            id="v-loginFieldPhone"
                            v-model="user.phone_number"
                            :state="
                              errors.length > 0 ? false : valid ? true : null
                            "
                            placeholder="5555555555"
                            size="lg"
                            maxlength="10"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <!-- Products supplied by supplier -->
            <tab-content
              title="Productos que provee"
              :before-change="validateProducts"
            >
              <b-row>
                <b-col cols="12" class="my-2"> </b-col>
                <b-col>
                  <add-store-products-to-supplier-search-bar />
                </b-col>
              </b-row>
            </tab-content>

            <!-- Resumen de información -->
            <tab-content title="Confirmar">
              <b-row>
                <b-col>
                  <div class="mb-1">
                    <p class="mb-0 text-muted">Nombre:</p>
                    <p class="display-5">
                      {{ user.name }}
                    </p>
                    <!-- 
                    <p class="mb-0 text-muted">
                      Correo:
                    </p>
                    <p class="display-5">
                      {{ user.email }}
                    </p>
                    -->
                    <p class="mb-0 text-muted">Celular:</p>
                    <p class="display-5">
                      {{ user.phone_number }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import vSelect from "vue-select"
import { mapActions, mapGetters } from "vuex"
import { FormWizard, TabContent } from "vue-form-wizard"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email } from "@validations"

import AddStoreProductsToSupplierSearchBar from "@/views/stores/AddStoreProductsToSupplierSearchBar.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import router from "@/router"

import "vue-form-wizard/dist/vue-form-wizard.min.css"
import "@/@core/scss/vue/libs/vue-wizard.scss"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    FormWizard,
    TabContent,
    ToastificationContent,
    AddStoreProductsToSupplierSearchBar,

    vSelect,
  },
  data() {
    return {
      user: {
        name: null,
        //email: null,
        phone_number: null,
        role_resource_id: null,
        product_attributes: {
          product_id: null,
          store_id: null,
        },
      },
      required,
      email,
      loading: false,
      marker: {},
      center: { lat: 25.6866142, lng: -100.3161126 },
      zoom: 10,
    }
  },
  computed: {
    ...mapGetters("users", ["getScopedProducts"]),
  },
  methods: {
    ...mapActions("users", ["registerEstablishmentSupplier"]),
    ...mapActions("stores", ["fetchStores"]),
    ...mapActions("establishments", ["fetchEstablishments"]),

    formSubmitted() {
      this.loading = true
      this.user.role_resource_id = router.currentRoute.params.id
      this.user.product_attributes = this.getScopedProducts
      this.user.phone_number = `${52}${this.user.phone_number}`
      this.registerEstablishmentSupplier(this.user)
        .then(() => {
          this.$swal({
            title: "Usuario registrado exitosamente!",
            text: "Revisa tus mensajes de texto para terminar tu registro.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
          this.$router.push({
            name: "establishment-proveedores",
            params: { id: this.$route.params.id },
          })
        })
        .catch((error) => {
          if (error.response.data.messages) {
            this.$swal({
              title: "Error!",
              text: error.response.data.messages[0],
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            })
          }
        })
        .then(() => {
          this.loading = false
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateProducts() {
      return new Promise((resolve, reject) => {
        if (this.getScopedProducts.length) {
          resolve(true)
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "CoffeeIcon",
              variant: "danger",
              text: "Debes seleccionar al menos un producto",
            },
          })
          reject()
        }
      })
    },
    validationCelForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountCelRules.validate().then((success) => {
          if (/[0-9]{10}/.test(this.user.phone_number)) {
            // Code for making email mandatory /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email) &&
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          } else {
            reject()
          }
        })
      })
    },
    validationDirForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountDirRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
.wizard-icon-container {
  border-radius: 6px !important;
  background: red;
}
.wizard-nav.wizard-nav-pills {
  display: none;
}
</style>
